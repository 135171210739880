
.container {
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.top {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 30%;
  background-color: #fff5a4;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 50px;
  color: #1c4386;
}

.top p {
  vertical-align: top;
}

.bottom {
  position: fixed;
  top: 70%;
  right: 0;
  left: 0;
  bottom: 0;
  background-color:#1c4386;
  text-align:center;
  color:#FFFFFF;
}

.bottom button {
  width: 100%;
  height: 100%;
  background-color: #1c4386;
  color: white;
  font-size: 50px;
}

.stats {
  position: absolute;
  bottom: 0;
  text-align: center;
  font-size: 20px;
}

.Everywhere {
  color: #161010;
  font-size: 30px;
}
.Common {
  color: #777373;
  font-size: 30px;
}
.Uncommon {
  color: #59e262;
  font-size: 30px;
}
.VeryUncommon {
  color: #a5f1ff;
  font-size: 30px;
}
.Rare {
  color: #389af6;
  font-size: 40px;
}
.Epic {
  color: #f24aff;
  font-size: 50px;
}

.epicAnim {
  animation: blinkingEpic 1.2s infinite;
}

.Legendary {
  color: #ffbf17;
  font-size: 60px;
}

.legendAnim {
  animation: spin 5s infinite, blinkingLegend 1.2s infinite;
  animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes blinkingEpic{
  0%{     color: #f24aff;    }
  49%{    color: #f24aff; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #f24aff;    }
}

@keyframes blinkingLegend{
  0%{     color: #ffbf17;    }
  49%{    color: #ffbf17; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #ffbf17;    }
}